exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-c-compilation-process-md": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/c-compilation_process.md" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-c-compilation-process-md" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-lsp-essentials-in-10-minutes-md": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/lsp essentials in 10 minutes.md" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-lsp-essentials-in-10-minutes-md" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-type-faster-than-people-can-read-md": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/type-faster-than-people-can-read.md" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-type-faster-than-people-can-read-md" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-v-model-explained-md": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/v-model-explained.md" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-v-model-explained-md" */),
  "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-vim-motions-blazing-fast-editng-md": () => import("./../../../src/pages/blogs/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/vim-motions-blazing-fast-editng.md" /* webpackChunkName: "component---src-pages-blogs-mdx-frontmatter-slug-js-content-file-path-articles-vim-motions-blazing-fast-editng-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-productivity-index-js": () => import("./../../../src/pages/productivity/index.js" /* webpackChunkName: "component---src-pages-productivity-index-js" */),
  "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-c-compilation-process-md": () => import("./../../../src/pages/productivity/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/c-compilation_process.md" /* webpackChunkName: "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-c-compilation-process-md" */),
  "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-lsp-essentials-in-10-minutes-md": () => import("./../../../src/pages/productivity/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/lsp essentials in 10 minutes.md" /* webpackChunkName: "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-lsp-essentials-in-10-minutes-md" */),
  "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-type-faster-than-people-can-read-md": () => import("./../../../src/pages/productivity/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/type-faster-than-people-can-read.md" /* webpackChunkName: "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-type-faster-than-people-can-read-md" */),
  "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-v-model-explained-md": () => import("./../../../src/pages/productivity/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/v-model-explained.md" /* webpackChunkName: "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-v-model-explained-md" */),
  "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-vim-motions-blazing-fast-editng-md": () => import("./../../../src/pages/productivity/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/vim-motions-blazing-fast-editng.md" /* webpackChunkName: "component---src-pages-productivity-mdx-frontmatter-slug-js-content-file-path-articles-vim-motions-blazing-fast-editng-md" */),
  "component---src-pages-series-index-js": () => import("./../../../src/pages/series/index.js" /* webpackChunkName: "component---src-pages-series-index-js" */),
  "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-c-compilation-process-md": () => import("./../../../src/pages/series/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/c-compilation_process.md" /* webpackChunkName: "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-c-compilation-process-md" */),
  "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-lsp-essentials-in-10-minutes-md": () => import("./../../../src/pages/series/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/lsp essentials in 10 minutes.md" /* webpackChunkName: "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-lsp-essentials-in-10-minutes-md" */),
  "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-type-faster-than-people-can-read-md": () => import("./../../../src/pages/series/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/type-faster-than-people-can-read.md" /* webpackChunkName: "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-type-faster-than-people-can-read-md" */),
  "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-v-model-explained-md": () => import("./../../../src/pages/series/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/v-model-explained.md" /* webpackChunkName: "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-v-model-explained-md" */),
  "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-vim-motions-blazing-fast-editng-md": () => import("./../../../src/pages/series/{mdx.frontmatter__slug}.js?__contentFilePath=/workspace/articles/vim-motions-blazing-fast-editng.md" /* webpackChunkName: "component---src-pages-series-mdx-frontmatter-slug-js-content-file-path-articles-vim-motions-blazing-fast-editng-md" */)
}

